<template>
  <div class="p-0 p-lg-2">
    <div class="p-3 p-lg-4 card">
      <h4 class="font-weight-bold mb-3">{{ $t(`${prefixLang}.main-title`) }}</h4>
      <p class="font-size-md access-rules-text m-0">
        {{ $t(`${prefixLang}.subtitle-text.part-1`) }}
        <br /><br />
        {{ $t(`${prefixLang}.subtitle-text.part-2`) }}
        <a @click="$router.push({ name: 'MyTeam' })" target="_blank" class="tlw-text-purple-500 redirect-anchor">{{
          $t(`${prefixLang}.subtitle-text.text-btn`)
        }}</a>
        {{ $t(`${prefixLang}.subtitle-text.part-3`) }}
        <br /><br />
        {{ $t(`${prefixLang}.subtitle-text.part-4`) }}
      </p>
      <hr class="divider w-100" />
      <h4 class="font-weight-bold mb-4">{{ $t(`${prefixLang}.diff-title`) }}</h4>
      <div class="d-flex flex-column flex-lg-row w-100 tlw-gap-5 p-0 pl-lg-1 pr-lg-4 ">
        <div class="moderator-container col-12 col-md-7 p-4">
          <div class="d-flex align-items-center mb-4">
            <div class="rounded-circle moderator-powers d-flex justify-content-center align-items-center">
              <hs-icon icon="crown" size="14" />
            </div>
            <h4 class="font-weight-bold ml-2">{{ $t(`${prefixLang}.diff-powers.moderator.title`) }}</h4>
          </div>
          <p class="access-rules-text mb-4">{{ $t(`${prefixLang}.diff-powers.moderator.subtitle`) }}</p>
          <ul class="ml-4">
            <li v-for="(powers, index) in livePowers.moderator_powers" :key="index">{{ powers }}</li>
          </ul>
        </div>
        <div class="moderator-container col-12 col-md-5 p-4">
          <div class="d-flex align-items-center mb-4">
            <div class="rounded-circle moderator-powers d-flex justify-content-center align-items-center">
              <hs-icon icon="user" size="14" />
            </div>
            <h4 class="font-weight-bold ml-2">{{ $t(`${prefixLang}.diff-powers.participant.title`) }}</h4>
          </div>
          <p class="access-rules-text mb-4">{{ $t(`${prefixLang}.diff-powers.participant.subtitle`) }}</p>
          <ul class="ml-4">
            <li v-for="(powers, index) in livePowers.participant_powers" :key="index">{{ powers }}</li>
          </ul>
        </div>
      </div>
      <div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center mt-5">
        <img
          :src="require('@/assets/images/SparkAffiliates/LandingPage/affiliate-icon.svg')"
          class="mr-4 mb-4 mb-lg-0"
        />
        <p class="access-rules-text m-0">
          {{ $t(`${prefixLang}.bottom-text`) }}
        </p>
      </div>
      <div class="alert-info-silver mt-4">
        <hs-icon icon="info-circle" size="14" class="mr-2" />
        <span class="font-size-sm">
          {{ $t(`${prefixLang}.silver-info`) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import LiveMeetPowers from '@/data/live_meet_powers.js';

export default {
  data() {
    return {
      livePowers: LiveMeetPowers()[0],
      prefixLang: 'sparkmembers.contents.views.lives.access-rules',
    };
  },
};
</script>

<style lang="scss" scoped>
.alert-info-silver {
  background: #e3e3e3;
  padding: 12px;
  color: #6f6f6f;
  font-weight: 400;
  line-height: 20.3px;
  font-size: 'Mulish';
  border-radius: 8px;
  display: flex;
  align-items: center;
}
ul {
  list-style: disc;
  color: $color-neutral-600;
}
.access-rules-text {
  font-size: 1rem;
  color: $color-neutral-600;
}

.moderator-container {
  box-shadow: 0px 8px 24px 0px #3f3f3f29;
  border-radius: 8px;
}
.moderator-powers {
  background: #ead3fe;
  height: 36px;
  width: 36px;
  i {
    color: $purple-dark;
  }
}
.divider {
  border: 1px solid #cfcfcf;
  margin: 32px 0;
}
.redirect-anchor {
  color: $purple-dark !important;
  cursor: pointer;
  text-decoration: underline !important;
  &:hover {
    color: $light-red !important;
  }
}
</style>
