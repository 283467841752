export default () => [
  {
    moderator_powers: [
      'Silenciar todos os participantes;',
      'Permitir que outro participante abra o microfone;',
      'Compartilhar a tela;',
      'Compartilhar um vídeo ou áudio;',
      'Iniciar uma gravação;',
      'Criar novas salas;',
      'Enviar participantes para essas novas salas, e também puxá-los de volta para a sala principal;',
      'Remover participante.',
    ],
    participant_powers: [
      'Falar, enquanto o moderador permitir;',
      'Transitar entre salas criadas',
      'Ativar ou desativar a própria câmera;',
      'Enviar mensagens no chat;',
      'Enviar gifs e reações.',
    ],
  },
];
